import React, { useState } from "react";
import styled from "styled-components";
import SEO from "../components/SEO";
import { Link } from "gatsby";
import homeImage from "../images/appscom-header-image.png";
import { Home } from "../components/home";
import {
  ColoredSpan,
  StyledLink,
  StyledA,
  TitleH2Comp,
  CardContainer,
  CommonCard,
  VisitLink,
  CardH4,
  CardP,
  ReviewCardContainer,
  ReviewCard,
  ReviewLowerSection,
  ReviewCardLowerText,
  ImageSection,
  CommonArrowButton,
  ButtonArrow,
  ImageTitleH2,
} from "../components/common";
import icon from "../images/icon.svg";
import icon2 from "../images/icon-2.svg";
import icon3 from "../images/icon-3.svg";
import icon4 from "../images/icon-4.svg";
import icon5 from "../images/icon-5.svg";
import icon6 from "../images/icon-6.svg";
import icon7 from "../images/icon-7.svg";
import icon8 from "../images/icon-8.svg";
import segmentlyImage from "../images/segmently-image.png";
import aboutUsImage from "../images/about-us-image.png";
import visitArrow from "../images/arrow.svg";
import buttonArrow from "../images/arrow-white.svg";

const UpperMainContainer = styled.div`
  display: grid;
  background-color: #fffbf8;
`;
const DarkSection = styled.div`
  display: grid;
  grid-template-columns: 35% minmax(620px, 700px);
  justify-content: start;
  grid-gap: 30px;
  padding: 80px 8%;
  background-color: #2d2d51;
  color: #fff;
  @media (max-width: 1100px) {
    grid-template-columns: 280px 1fr;
    padding: 80px 6%;
  }
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 575px) {
    padding: 50px 8%;
  }
`;
const DarkLeftSection = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
`;
const DarkH2 = styled.h2`
  max-width: 280px;
  font-weight: 600;
  @media (max-width: 575px) {
    text-align: center;
    max-width: initial;
  }
`;
const DarkBar = styled.div`
  width: 4px;
  margin: 30px 0;
  background-color: #424262;
  border-radius: 2px;
  @media (max-width: 800px) {
    display: none;
  }
`;
const DarkAppSectionContainer = styled.div`
  display: grid;
  gap: 50px 0;
  @media (max-width: 575px) {
    gap: 80px 0;
  }
`;
const DarkAppSection = styled.div`
  display: grid;
  grid-template-columns: 220px 1fr;
  gap: 0 10%;
  @media (max-width: 1100px) {
    gap: 0 7%;
  }
  @media (max-width: 575px) {
    grid-template-columns: minmax(220px, 260px);
    justify-content: center;
  }
`;
const LargeNumber = styled.div`
  color: #424262;
  font-size: 60px;
  font-weight: 700;
  letter-spacing: 3.6px;
  margin-bottom: 10px;
`;
const DarkText = styled.p`
  color: #ffffffb3;
`;
const VisitLinkSection = styled.div`
  display: grid;
  grid-template-columns: auto 25px;
  justify-content: start;
  align-items: center;
  gap: 10px;
  margin-top: 25px;
  &:hover ${ButtonArrow} {
    width: 22px;
  }
`;

// markup
const IndexPage = () => {
  return (
    <>
      <SEO
        title="Shopify Apps"
        description="Shopify Apps That Make Your E-Commerce Journey Easier"
        keywords="Shopify apps, Shopify tools"
      />
      <UpperMainContainer>
        <Home
          homeH1={
            <>
              Shopify Apps That Will Make Your{" "}
              <ColoredSpan>E-Commerce Journey</ColoredSpan> Easier
            </>
          }
          homeText={`Automate your Shopify store with the best apps to provide an outstanding experience to your customers and accelerate your sales.`}
          image={<img src={homeImage} alt="video thumbline" width="100%" />}
          buttonLink="https://apps.shopify.com/partners/apps2grow"
          ButtonText="Browse Apps"
        />
        <TitleH2Comp
          content={
            <>
              Apps That Will Help You To
              <ColoredSpan> Boost Your Sales.</ColoredSpan>
            </>
          }
        />
        <CardContainer>
          <CommonCard>
            <img src={icon} alt="well designed" />
            <CardH4>Well Designed Shopify Apps</CardH4>
            <CardP>
              Apps that are built for reducing the pressure of Shopify merchants
              and help them to get more sales
            </CardP>
          </CommonCard>
          <CommonCard>
            <img src={icon2} alt="custom design" />
            <CardH4>Design Campaigns In Your Way</CardH4>
            <CardP>
              Use the best templates to design campaigns for your store visitors
              and attract them.
            </CardP>
          </CommonCard>
          <CommonCard>
            <img src={icon3} alt="increase conversions" />
            <CardH4>Increase Conversions</CardH4>
            <CardP>
              Inspire potential customers to make decisions and subscribe to the
              mailing list.
            </CardP>
          </CommonCard>
          <CommonCard>
            <img src={icon4} alt="Let Your Visitors Know You Better" />
            <CardH4>Let Your Visitors Know You Better</CardH4>
            <CardP>
              Inform your store visitors about yourself to gain trust with a
              simple Shopify app
            </CardP>
          </CommonCard>
          <CommonCard>
            <img src={icon5} alt="awesome support" />
            <CardH4>Awesome Support</CardH4>
            <CardP>
              Get the best support from our team to solve your issue
            </CardP>
          </CommonCard>
          <CommonCard>
            <img src={icon6} alt="no custom coding" />
            <CardH4>No Custom Coding</CardH4>
            <CardP>
              No technical skill is required to use the apps of Appscom. Just
              install and get quick ROI
            </CardP>
          </CommonCard>
          <CommonCard>
            <img src={icon7} alt="explore apps before you pay" />
            <CardH4>Explore The Apps Before You Pay</CardH4>
            <CardP>
              Get a free trial and use the app you need for your store
            </CardP>
          </CommonCard>
          <CommonCard>
            <img src={icon8} alt="email autoresponders" />
            <CardH4>Get Emails In Your Email Autoresponders</CardH4>
            <CardP>
              Collect email addresses in your email marketing tools with our
              direct integration
            </CardP>
          </CommonCard>
        </CardContainer>
        <DarkSection>
          <DarkLeftSection>
            <DarkH2>The Checkout Experience Suite</DarkH2>
            <DarkBar />
          </DarkLeftSection>
          <DarkAppSectionContainer>
            <DarkAppSection>
              <div>
                <img src={segmentlyImage} alt="segmently" width="100%" />
              </div>
              <div style={{ display: `grid` }}>
                <LargeNumber>01</LargeNumber>
                <h3>Segmently</h3>
                <DarkText>
                  Design and run campaign in a minute: show popups and
                  promotions, capture emails and get followers.
                </DarkText>
                <VisitLink
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://apps.shopify.com/segmently"
                >
                  <VisitLinkSection>
                    <div>Visit App</div>
                    <ButtonArrow>
                      <img src={visitArrow} alt="arrow" width="100%" />
                    </ButtonArrow>
                  </VisitLinkSection>{" "}
                </VisitLink>
              </div>
            </DarkAppSection>

            <DarkAppSection>
              <div>
                <img src={aboutUsImage} alt="about us" width="100%" />
              </div>
              <div style={{ display: `grid` }}>
                <LargeNumber>02</LargeNumber>
                <h3>Our Story - About Us</h3>
                <DarkText>
                  Make your About Us page, not just a story but a powerful tool
                  to make your brand stand out from the other Shopify stores.
                </DarkText>
                <VisitLink
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://apps.shopify.com/about-us"
                >
                  <VisitLinkSection>
                    <div>Visit App</div>
                    <ButtonArrow>
                      <img src={visitArrow} alt="arrow" width="100%" />
                    </ButtonArrow>
                  </VisitLinkSection>{" "}
                </VisitLink>
              </div>
            </DarkAppSection>
          </DarkAppSectionContainer>
        </DarkSection>
        <TitleH2Comp
          content={
            <>
              Here’s What
              <ColoredSpan> People Are Saying</ColoredSpan>
            </>
          }
        />
        <ReviewCardContainer>
          <div>
            <ReviewCard>
              <CardP>
                “Using the Facebook like campaign option with a discount code
                really increased our sales. Highly recommended!.”
              </CardP>
              <ReviewLowerSection>
                <ReviewCardLowerText>Core Trainer</ReviewCardLowerText>
                <ReviewCardLowerText date>July 7, 2021</ReviewCardLowerText>
              </ReviewLowerSection>
            </ReviewCard>
            <ReviewCard>
              <CardP>
                “Great App! The best thing in the app is the quick service from
                the support team 🙂 LOVE IT”
              </CardP>
              <ReviewLowerSection>
                <ReviewCardLowerText>Inspiring Wave</ReviewCardLowerText>
                <ReviewCardLowerText date>April 22, 2021</ReviewCardLowerText>
              </ReviewLowerSection>
            </ReviewCard>
          </div>
          <div>
            <ReviewCard>
              <CardP>
                “Amazing APP! Interface is simple, heaps of features and great
                straight forward flat monthly fee. Highly Recommended.”
              </CardP>
              <ReviewLowerSection>
                <ReviewCardLowerText>Endevz</ReviewCardLowerText>
                <ReviewCardLowerText date>June 10, 2021</ReviewCardLowerText>
              </ReviewLowerSection>
            </ReviewCard>
            <ReviewCard>
              <CardP>
                “The app did exactly what I needed. It is simple to use and
                creates a nice look on the page. I don’t like that I cannot
                remove the branding at the bottom, but that is minor. I did have
                a problem with the app and…”
              </CardP>
              <ReviewLowerSection>
                <ReviewCardLowerText>Blue Flamingo Studio</ReviewCardLowerText>
                <ReviewCardLowerText date>March 8, 2021</ReviewCardLowerText>
              </ReviewLowerSection>
            </ReviewCard>
          </div>
          <div>
            <ReviewCard>
              <CardP>
                “Great app. Olaf is always available for questions.”
              </CardP>
              <ReviewLowerSection>
                <ReviewCardLowerText>
                  Bare Skin By Dr Bollmann
                </ReviewCardLowerText>
                <ReviewCardLowerText date>May 7, 2021</ReviewCardLowerText>
              </ReviewLowerSection>
            </ReviewCard>
            <ReviewCard>
              <CardP>
                “VERY basic. It doesn’t allow to customize the page, it’s very
                generic. It’s a cool concept, so I hope the app is further
                developed and have better features…”
              </CardP>
              <ReviewLowerSection>
                <ReviewCardLowerText>Kallie Shoes</ReviewCardLowerText>
                <ReviewCardLowerText date>April 22, 2021</ReviewCardLowerText>
              </ReviewLowerSection>
            </ReviewCard>
          </div>
        </ReviewCardContainer>
        <ImageSection>
          <ImageTitleH2>
            We Touched The Life Of{" "}
            <ColoredSpan color="#2D2D51"> 600+</ColoredSpan> Merchants’ Custom
            Development
          </ImageTitleH2>
          <VisitLink
            target="_blank"
            rel="noopener noreferrer"
            href="https://apps.shopify.com/partners/apps2grow"
          >
            <CommonArrowButton>
              <div>Browse Apps</div>
              <ButtonArrow>
                <img
                  src={buttonArrow}
                  alt="arrow"
                  style={{ marginLeft: `10px` }}
                />
              </ButtonArrow>
            </CommonArrowButton>
          </VisitLink>
        </ImageSection>
      </UpperMainContainer>
    </>
  );
};

export default IndexPage;
